<template>
  <b-card style="min-height: 450px" class="h-100">
    <b-card-title class="text-center mt-1 text-bold" style="font-size: 16px; font-weight: bold">
      <i class="far fa-credit-card mx-1" style="color: #f3a152"></i>

      {{ $t('payments') }}

    </b-card-title>
    <b-table
      :items="payments"
      :fields="fields"
      class="mt-5 custom-header"
      striped
      bordered
      outlined
    >

      <template #cell(type)="data">
        {{ msg(data.value) }}
      </template>
      <template #cell(paymentDate)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(dateToCharge)="data">
        {{ formatDateOnly(data.value) }}
      </template>

      <template #cell(amount)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(paid)="data">
       <span v-if="data.value" style="color: #00c853"> {{ msg('Yes')}}</span>
       <span v-else style="color: red "> {{ msg('No') }}</span>
      </template>

      <template #cell(id)="row">
        <div v-if="row.item.id!=null" class="d-flex flex-column align-items-center">
          <b-button :disabled="row.item.paid" size="sm" @click="markAsPaid(row.item, row.index, $event.target)"
                    variant="success">
            {{ msg('Mark as paid') }}
          </b-button>
          <b-button class="mt-1"
                    :disabled="row.item.paid || isChargeDatePassed(row.item.dateToCharge)"
                    size="sm"
                    @click="sendReminderMail(row.item, row.index, $event.target)"
                    variant="warning">
            {{ msg('Reminder mail') }}
          </b-button>

        </div>
      </template>

    <template #cell(brand)="data">
       <span v-if="data.value !=null && data.value!==''"> {{ data.value }} {{' **** '+data.item.digits}}</span>
      <span v-else></span>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'PaymentClientTable',
  props: {
    title: {
      type: String,
      default: 'Payments'
    },
    contractId: {
      type: String,
      default: null,
      required: true
    },
    payments: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      fields:null
    };
  },
  created() {

    this.setFields();
  },
  watch: {

  },
  methods: {
    ...mapActions("realEstateContracts", ["markPaymentAsPaid","sendReminderMailPayment"]),

    setFields() {
      this.fields =  [
        {key: 'type', label: this.msg('Type')},
        {key: 'reason', label: this.msg('Reason')},
        {key: 'amount', label: this.msg('Amount')},
        {key: 'paid', label: this.msg('Paid')},
        {key: 'brand', label: this.msg('Card')},
        {key: 'paymentDate', label: this.msg('Creationdate')},
        {key: 'dateToCharge', label: this.msg('Date for next payment')},
        {key: 'id', label: this.msg('Actions')}
      ];
    },
    formatDate(date) {
      if (!date) return '';
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }
    , formatDateOnly(date) {
      if (!date) return '';
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }
    ,
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    isChargeDatePassed(chargeDate) {
      if (!chargeDate) return false; // or true, depending on desired default behavior
      const today = new Date().toISOString().split('T')[0]; // "yyyy-MM-dd"
      console.log("today",today)
      return chargeDate > today; // returns true if chargeDate has passed
    },
    markAsPaid(payment, index, target) {
      let $this = this;
      this.markPaymentAsPaid({
        contractId: this.contractId,
        paymentId: payment.id,
      }).then(approve => {
        $this.$emit('submitted')
      })
    },
    sendReminderMail(payment, index, target) {
      let $this = this;
      this.sendReminderMailPayment({
        contractId: this.contractId,
        paymentId: payment.id,
      }).then(approve => {
        $this.$emit('submitted')
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.b-table  th {
  background-color: #007bff !important; // Bootstrap primary color
  color: white;
}
</style>
